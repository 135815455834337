footer {
  text-align: center;
  border: 1px solid #E4E4E4;

  @include breakpoint(medium up) {
    .border-right {
      border-right: 1px solid #E1E1E1;
    }
  }

  li {
    list-style-type: none;

    a {
      color: #6B7C93;
      font-weight: 600;
    }
  }

  .main-footer {
    padding-top: rem-calc(12);
    padding-bottom: rem-calc(12);
    margin: rem-calc(24) auto;

    .excel-logo {
      display: block;
      margin: 0 auto rem-calc(24) auto;

      img {
        max-height: rem-calc(35);
      }

      @include breakpoint(medium up) {
        float: left;
        display: inline;
        margin: 0 0 rem-calc(24) 0;
      }
    }

    ul {
      margin: rem-calc(6);
    }

    li {
      text-align: center;

      @include breakpoint(medium up) {
        text-align: left;
      }
    }

    a {
      font-size: rem-calc(18);
      font-weight: 600;
      color: $dark-grey;

      &:hover {
        color: $dark-blue;
      }

      @include breakpoint(medium up) {
        font-size: rem-calc(14);
      }
    }

    .certification-logos {
      margin-top: rem-calc(45);
      display: block;

      li {
        display: inline-block;
      }

      img {
        margin: rem-calc(8) rem-calc(18) rem-calc(8) rem-calc(0);
        height: rem-calc(65);
        width: auto;

        @include breakpoint(large up) {
          margin: auto rem-calc(6);
          height: rem-calc(65);
        }
      }
    }
  }

  .bottom-footer {
    background: #35353B;
    ul {
      min-height: rem-calc(22);
      margin: rem-calc(12);
    }

    .bottom-footer-copyright {
      display: block;
    }

    li {
      list-style-type: none;
      font-size: rem-calc(14);
      color: $white;
      font-weight: 700;
      letter-spacing: .75;
      display: inline;

      &:first-child {
        margin-left: rem-calc(0);
      }

      img {
        display: inline;
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
      }

      @include breakpoint(medium up) {
        display: inline;

        &.bottom-footer-copyright {
          float: left;
        }

        &.bottom-footer-contact {
          float: right !important;
          margin-left: rem-calc(18);
        }
      }
    }
  }
}

// Call to Action
.contactus-cta {
  z-index: 1;
  background: #fa3350;

  @include breakpoint(medium up) {
    max-height: rem-calc(600);
  }

  .contactus-cta-details {
    text-align: center;

    .icon-left-small+.icon-left-small {
      margin-top: rem-calc(32);
    }

    @include breakpoint(medium up) {
      text-align: left;
    }
  }
}

// Variables
.contactus-cta {
  background-color: #CDE0F5;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.test-one {
  border: 1px solid red;
}

.circle-ripple {
  z-index: -15;
  position: absolute;
  top: rem-calc(46);
  right: 0%;
  left: 0%;
  text-align: center;
  margin: 0 auto;
  background: rgba(255,255,255, 0.8);
  width: 30em;
  height: 30em;
  border-radius: 50%;
  animation: ripple 3.0s linear infinite;

  @include breakpoint(small only) {
    width: 14em;
    height: 14em;
    top: 10%;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(#fff, 0.4),
    0 0 0 10em rgba(#fff, 0.3),
    0 0 0 20em rgba(#fff, 0.3),
    0 0 0 30em rgba(#fff, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10em rgba(#fff, 0.4),
    0 0 0 20em rgba(#fff, 0.3),
    0 0 0 30em rgba(#fff, 0.3),
    0 0 0 40em rgba(#fff, 0);
  }
}

.footer-links {
  @include breakpoint(small only) {
    margin-bottom: rem-calc(-12);
  }
}
