.logos-bar {
  text-align: center;
  border-bottom: 1px solid #f0f0f3;
  background: #CEDEF1;

  ul {
    margin: rem-calc(10 0 6 0);
    padding: rem-calc(0);
  }

  li {
    text-align: center;
    list-style-type: none;
    display: inline-block;
    margin: rem-calc(0 3);
    &:first-child {margin-left: 0;}
    &:last-of-type {margin-right: 0;}

    @include breakpoint(medium) {
      margin: rem-calc(0 4);
    }

    @include breakpoint(large up) {
      margin: rem-calc(0 32);
    }
  }

  .small-screen {
    @include breakpoint(small only) {
      display: none;
    }
  }

  .logo-bar-customers {
    display: inline-block;
    background: url('../img/logosbar.png') no-repeat;
    background-size: cover;
    height: rem-calc(30);
    width: rem-calc(1050);
    background-position: center center;
    margin: rem-calc(10 0 5 0);
  }
}
