.accordion {
  margin-top: rem-calc(60);
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: rem-calc(5);
  border-left: 0;
  border-right: 0;

  .accordion-item.is-active {
    // background: #F7F7FA;
  }

  .accordion-title {
    font-weight: 700;
    color: $dark-blue;
    letter-spacing: rem-calc(.5);
    line-height: 2;
    border-left: 0;
    border-right: 0;

    &:hover, &:focus {
      background: #F7F7FA;
      text-decoration: none;
    }

    &:focus {
      background: #fff;
      border-left: 0;
      border-right: 0;
    }
  }

  .accordion-content {
    // background: #F7F7FA;
    border: 0px solid transparent;

    p, li {
      font-size: rem-calc(18);
      line-height: 1.5;
      font-family: 'Georgia', sans-serif;
      color: $dark-grey;
    }
  }
}

.faq-button {
  margin-top: rem-calc(50);
}
