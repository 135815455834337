.booking-hero {
  padding-top: rem-calc(116);
  padding-bottom: rem-calc(30);
  background: url('../img/excel-scheduler-hero-background-small.png') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  text-align: center;

  @include breakpoint(medium) {
    padding-top: rem-calc(150);
    padding-bottom: rem-calc(46);
    text-align: left;
    background: url('../img/excel-scheduler-hero-background.png') no-repeat;
  }

  @include breakpoint(large) {
    padding-top: rem-calc(120);
    padding-bottom: rem-calc(50);
  }

  img {
    @include breakpoint(large) {
    }
  }

  h1 {
    margin-top: rem-calc(56);
    font-weight: 700;
    font-size: rem-calc(36);
    letter-spacing: rem-calc(0);
    line-height: 1.25;

    @include breakpoint(medium) {
      font-size: rem-calc(42);
      text-align: left;
      margin-top: rem-calc(20);
    }

    @include breakpoint(large) {
      font-size: rem-calc(56);
      text-align: left;
      margin-top: rem-calc(60);
    }
  }

  p {
    font-size: rem-calc(22);
  }

  .button.pink {
    margin-top: rem-calc(24);
    font-size: rem-calc(18);
  }
}

.booking {
  @include breakpoint(medium) {
    padding-top: rem-calc(70);
    padding-bottom: rem-calc(70);
  }

  &.lightblue-background {
    background: #F3F5FB;
  }

  .section-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: $pink;
    font-size: rem-calc(18);
    margin-bottom: rem-calc(16);
  }

  .text-center {
    text-align: center;
  }

  h2 {
    margin-top: rem-calc(32);
    margin-bottom: rem-calc(24);
    font-weight: 700;
    font-size: rem-calc(36);
    line-height: 1.25;

    @include breakpoint(large) {
      font-size: rem-calc(40);
    }
  }

  p {
    font-size: rem-calc(20);
  }

  @include breakpoint(small only) {
    .image-padding-top {margin-top: rem-calc(30);}
    .image-padding-bottom {margin-bottom: rem-calc(30);}
  }

  .booking-benefits {
    padding: rem-calc(20);

    @include breakpoint(medium) {
      padding: rem-calc(50);
    }

    img {
      width: rem-calc(56);
      margin-bottom: rem-calc(14);
      margin-top: rem-calc(20);

      @include breakpoint(medium) {
        margin-bottom: rem-calc(14);
        margin-top: rem-calc(40);
      }
    }

    h3 {font-size: rem-calc(20);}
    p {font-size: rem-calc(18);}
  }

  &.cta {
    z-index: 1;
    background: url('../img/excel-scheduler-hero-background-small.png') no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;

    @include breakpoint(medium) {
      padding: rem-calc(46);
    }

    h1 {
      margin-top: 0;
      font-weight: 700;
      font-size: rem-calc(36);
      line-height: 1.25;

      @include breakpoint(large) {
        font-size: rem-calc(42);
      }
    }

    p {
      font-size: rem-calc(22);
      margin-bottom: rem-calc(0);
    }

    .button {
      font-size: rem-calc(18);
      margin-top: rem-calc(28);
      margin-bottom: rem-calc(42);
    }

    .cta-details {
      p {font-size: rem-calc(20);}
    }
  }
}

.reveal {
  border-radius: rem-calc(8);
  padding: rem-calc(30 24);

  &:active, &:visited, &:focus {
    text-decoration: none;
  }

  label {
    color: $dark-grey;
    margin-top: rem-calc(16);
    font-size: rem-calc(16);
    font-weight: 600;
  }

  img {
    width: rem-calc(56);
    margin-bottom: rem-calc(14);
    margin-top: rem-calc(14);
  }

  h2 {
    font-size: rem-calc(28);
    font-weight: 700;
    text-align: center;

    @include breakpoint(large) {
      font-size: rem-calc(30);
    }
  }

  p {
    font-size: rem-calc(18);
    margin-bottom: rem-calc(30);
  }

  .tabs {
    box-shadow: none;
  }

  .tabs-title, .tabs-content {
    &:active, &:visited, &:focus {
      text-decoration: none !important;
    }
  }

  .tabs-title {
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(24);
    border: 2px solid $sky-blue;
    font-weight: 600;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .tabs-title:nth-child(2) {
    margin-left: rem-calc(-2);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

a.reveal-button.button.pink {
  margin-top: rem-calc(32);
  font-size: rem-calc(16);
  width: rem-calc(150);
}
