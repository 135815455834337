.menu li a {
  padding: rem-calc(2);

  &.active {
    color: $dark-blue;
  }
}

.navigation {
  background-color: $white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .25);
  z-index: 600;
  display: block;
  position: fixed;
  width: 100%;
  padding: rem-calc(12 20 12 12);

  @include breakpoint(medium) {
    padding: rem-calc(12);
  }

  li {
    margin: rem-calc(0 6 3 12);
    list-style-type: none;
    display: inline-block;

    @include breakpoint(medium only) {
      margin: rem-calc(0 3 3 3);
    }
  }

  .navigation-service-types {
      padding: 6px 0px;
      margin: 16px 0 0 0;

    li {
      margin: 0px;
      text-align: center;

      a {
        padding: rem-calc(3 8);
        margin: rem-calc(0);
        display: inline-block;

        &:hover {
          color: $white;
          background: $light-blue;
          -webkit-transition: .15s ease-in-out;
          -moz-transition: .15s ease-in-out;
          -ms-transition: .15s ease-in-out;
          -o-transition: .15s ease-in-out;
          transition: .15s ease-in-out;
        }
      }
    }
  }

  .excel-logo img {
    display: block;
    margin: 0 auto;
    width: auto;
    padding: rem-calc(6 12);
    max-height: rem-calc(42);
    margin-bottom: rem-calc(6);

    @include breakpoint(large) {
      float: left;
      margin-top: rem-calc(2);
      margin-bottom: rem-calc(0);
      max-height: rem-calc(40);
    }
  }
}

.navigation-links {
  text-align: center;

  @include breakpoint(large up) {
    margin-top: rem-calc(4);
    text-align: right;
  }

  li a {
    font-weight: 600;
    letter-spacing: .75px;
    color: $dark-grey;

    &:hover {
      color: $dark-blue;
    }
  }

  .navigation-contact-call {
    color: $dark-blue;
    padding-right: rem-calc(18);
    padding-left: rem-calc(18);
    font-weight: 600;
    letter-spacing: .75px;

    @include breakpoint(medium up) {
      border-left: 1px solid #E1E1E1;
      padding-right: rem-calc(18);
      padding-left: rem-calc(18);
    }
  }

  .navigation-contact-quote {
    padding: 0.7rem 1rem;
    color: $white;
    margin: 0;
    margin-left: rem-calc(-16);
    animation:myfirst 3s;
    -moz-animation:myfirst 3s infinite; /* Firefox */
    -webkit-animation:myfirst 3s infinite; /* Safari and Chrome */
    @-moz-keyframes myfirst /* Firefox */
    {
      0%   {background:#00B3EB}
      50%  {background:#4182D7}
      100%   {background:#00B3EB}
    }

    @-webkit-keyframes myfirst /* Firefox */
    {
      0%   {background:#00B3EB}
      50%  {background:#4182D7}
      100%   {background:#00B3EB}
    }

    &:hover {
      color: $dark-blue;
      animation: none;
      background: transparent;
      border: 1px solid $dark-blue;
    }
  }
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border-color: $dark-grey transparent transparent;
}

//Mobile
.off-canvas-menu {
  font-size: rem-calc(18);
  font-weight: 700;

  ul {
    padding: rem-calc(8);
  }

  li {list-style-type: none;}

  a {
    color: $dark-grey;
    line-height: 300%;

    &:focus {
      text-decoration: none;
    }

    &.active {
      color: $dark-blue;
    }
  }

  hr {
    margin: rem-calc(12) auto rem-calc(24) auto;
  }

  .off-canvas-menu-contact {
    line-height: 300%;
    color: $dark-blue;

    img {margin-right: rem-calc(12);}
  }
}

.help-callout {
  background: $dark-blue;
  z-index: 50;
  position: fixed;
  display: inline-block;
  width: 100%;

  p {
    margin: 4px auto;
    font-size: rem-calc(11);
    color: $white !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    text-align: center;
  }
}

.navigation-mobile {
  box-shadow: 0px 6px 10px rgba(36, 36, 36, 0.28);
  z-index: 50;
  background: $white;
  display: block;
  position: fixed;
  width: 100%;
  top: rem-calc(25);
  height: rem-calc(50);
  box-shadow: 0px 6px 10px rgba(36, 36, 36, 0.28);

  .off-canvas-button {
    margin: rem-calc(10);
    float: left;
    width: rem-calc(26);
    height: rem-calc(30);
    background: url('../img/navigation-icon.svg') no-repeat;
    background-size: cover;
  }

  .excel-logo {
    position: absolute;
    right: rem-calc(-4);
    top: rem-calc(10);
    background: url('../img/excel-logo-mobile.png') no-repeat;
    background-size: contain;
    width: rem-calc(108);
    height: rem-calc(30);
  }
}

.off-canvas {
  display: none;

  @include breakpoint(small only) {
    display: inline;
  }
}

#contact-excel-information:focus {
  outline: none;
}
