.card {
  font-family: 'Georgia', sans-serif;
  background: $white;
  padding: rem-calc(35);
  position: relative;
  border-radius: rem-calc(4);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  // border: 1px solid transparent;
  text-align: center;

  @include breakpoint(large up) {
    text-align: left;
  }

  &.collapse {
    padding: rem-calc(0);
  }

  &.service {
    display: block;

    h3 { color: $black;}

    &:hover {
      box-shadow: 0px 0px 40px #DDD;

      .services-image {
        // transform: scale(1.03);
      }
    }
  }

  .card-link {
    color: $dark-grey;
    display: block;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    letter-spacing: .5px;
    margin-top: rem-calc(32);
    font-size: rem-calc(15);

    &:hover {
      color: $dark-blue;
    }
  }

  p {
    color: $dark-grey;
  }

  ul {
    margin-left: 0;
  }

  li {
    font-size: rem-calc(16);
    font-family: Georgia, sans-serif;
    line-height: 2;
    list-style-type: none;
    color: $dark-grey;
  }

  .button {
    margin-bottom: 0;
    margin-top: rem-calc(16);
  }

  h3 {
    margin-bottom: rem-calc(16);
  }

  &.grey {
    background: #F7F7FA;
    border: 1px solid #f0f0f9;
    text-align: center;

    @include breakpoint(large up) {
      padding-left: rem-calc(56);
      text-align: left;
    }
  }

  .card-icon {
    margin-bottom: rem-calc(24);
    max-width: rem-calc(100);
    max-height: auto;
  }

  &.language-list {
    background: #F7F7FB;
    border: 1px solid #efeff9;
    padding: rem-calc(60 70);
  }

  .horizontal-card {
    padding: rem-calc(25);
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }
  }

  &.koy-quote {
    background: #F7F7FB;
    border: 1px solid #efeff9;
  }

  .services-icon {
    margin-top: -230px;
    min-height: rem-calc(100);
    width: rem-calc(100);
    margin: 0px auto 16px auto;
    background-position: center center;

    &.interpreting {
      background: url('../img/interpreting.svg') no-repeat;
    }

    &.translation {
      background: url('../img/translation.svg') no-repeat;
    }

    &.telephonic {
      background: url('../img/telephonic.svg') no-repeat;
    }
  }

  &.vertical {
    padding-top: rem-calc(260);

    .services-image {
      transition: .3s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      min-height: 14.5rem;
      width: 100%;
      background-position: center center;

      &.red-background {
        bottom: 0;
        right: 0;
        padding-top: 0 !important;
        background: url('../img/red-background.png') no-repeat;
        background-size: 100% rem-calc(200);
        background-position: bottom;
      }

      &.medical {
        background: url('../img/interpreting-medical.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
      }

      &.legal {
        background: url('../img/interpreting-legal.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
      }

      &.socialservices {
        background: url('../img/interpreting-socialservices.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
      }

      &.education {
        background: url('../img/interpreting-education.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
      }

      &.interpreting {
        background: url('../img/excel-services-interpreting.png') no-repeat;
        background-size: cover;
      }

      &.translation {
        background: url('../img/excel-services-translation.png') no-repeat;
        background-size: cover;
      }

      &.telephonic {
        background: url('../img/excel-services-telephonic.png') no-repeat;
        background-size: cover;
      }
    }

    .location-image {
      position: absolute;
      top: 0;
      left: 0;
      background: url('../img/location-map.png') no-repeat;
      background-size: cover;
      background-position: center;
      min-height: rem-calc(250);
      width: 100%;
    }
  }
}

.industry-types {
  margin-bottom: rem-calc(40);
}

.card.vertical.testimonial {
  padding: rem-calc(35);
}

.center-button-container {
  display: relative;
  align: right;
  margin-top: rem-calc(38);

  .center-button {
    width: rem-calc(200);
    display: block;
    margin: 0 auto;
  }
}

.card.vertical.request-quote {
  padding: rem-calc(36);
  bottom: 0;
  right: 0;
  background: url('../img/request-quote-background.png') no-repeat;
  background-size: 100% rem-calc(200);
  background-position: top;
}

.card-primary-image {
  &.linguist-resume {
    background: url('../img/interpreter-translator-resume.png') no-repeat;
    background-size: cover;
    background-position: right center;
    min-height: rem-calc(200);
    width: 100%
  }

  &.linguist-school {
    background: url('../img/linguists-education.jpg') no-repeat;
    background-size: cover;
    background-position: left center;
    min-height: rem-calc(200);
    width: 100%
  }

  &.values-1 {
    background: url('../img/about-1.png') no-repeat;
    background-size: cover;
    background-position: left center;
    min-height: rem-calc(200);
    width: 100%
  }

  &.values-2 {
    background: url('../img/values-2.png') no-repeat;
    background-size: cover;
    background-position: left top;
    min-height: rem-calc(200);
    width: 100%
  }

  &.values-3 {
    background: url('../img/laurie-desk-1.png') no-repeat;
    background-size: cover;
    background-position: left center;
    min-height: rem-calc(200);
    width: 100%
  }

  &.translation-quote {
    background: url('../img/translation-prices.jpg') no-repeat;
    background-size: cover;
    background-position: right center;
    min-height: rem-calc(200);
    width: 100%
  }

  &.translation-manager {
    background: url('../img/callout-pattern-medium.svg') no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center top;
    min-height: rem-calc(200);


    @include breakpoint(medium) {
      background: url('../img/callout-pattern-medium.svg') no-repeat;
      background-position: center top;
      background-size: cover;
      width: 100%
    }

    @include breakpoint(large up) {
      background: url('../img/callout-pattern.png') no-repeat;
      background-size: cover;
      background-position: right center;
      min-height: rem-calc(200);
      width: 100%;
    }

    img {
      margin-top: 40px;
      margin-bottom: 20px;
      width: 225px;
      height: auto;
      border-radius: 250px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

      @include breakpoint(large) {
        width: 215px;
        height: auto;
        margin: 40px auto auto 60px;
      }
    }
  }
}

.excel-stat {
  color: $pink;
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  letter-spacing: .5px;
  font-size: rem-calc(36);
}

.horizontal-card.translation-manager {
  text-align: center;
  .button + .button {
    margin-left: 0;
  }

  @include breakpoint(medium) {
    .button + .button {
      margin-left: 8px;
    }
  }

  @include breakpoint(large up) {
    text-align: left;
  }
}
