section {
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
  }

  @include breakpoint(large) {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
  }

  &.lightblue-background {
    background: #F3F5FB;
  }

  &.special {
    background: url(../img/language-background.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
  }

  &.pattern-background {
    background: url(../img/language-background.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
  }

  &.excel-stats {
    background: url(../img/excel-stats-background.png) no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding-top: rem-calc(16);
    padding-bottom: rem-calc(16);
  }
}

.industries {

  .industry-image {
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: rem-calc(36);
    text-align: center;

    @include breakpoint(medium up) {
      text-align: left;
      width: 80%;
      margin-bottom: rem-calc(0);
    }
  }

  ul {
    margin-bottom: rem-calc(32);
  }

  li {
    text-align: left;
    margin-left: rem-calc(-16);
    font-size: rem-calc(16);
    color: $dark-grey;
    font-family: 'Georgia', sans-serif;
    list-style: none;
    line-height: rem-calc(36);

    @include breakpoint(medium) {
      font-size: rem-calc(18);
      text-align: left;
    }

    &:first-child {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(20);
      font-weight: 700;
      color: $black;
    }
  }

  .card-icon {
    margin-bottom: rem-calc(10);
  }
}

.form-terms {
  font-family: 'Georgia', sans-serif;
  font-size: rem-calc(12);
  font-weight: 500;
  color: $black;
  text-align: left;
}

.industry-types h1, .industry-types .sub-header {
  text-align: center;

  @include breakpoint(medium) {
    text-align: left;
  }
}

.special-row {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.special-column {
  border-bottom: 1px solid #c7c7ce;
  background: #F7F7FA;
}

.special-column .columns:first-child p {
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: rem-calc(.75);
  font-size: rem-calc(18);
  font-weight: 700;
  background: #F7F7FA;
}

.special-column .columns:nth-child(1), .special-column .columns:nth-child(2)  {
  padding: rem-calc(12 20);
}

.special-last {
  padding-bottom: rem-calc(4);
}

.special-column .columns:last-child {
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: rem-calc(.75);
  font-size: rem-calc(20);
  background: #FFF;
  padding: rem-calc(12 20);
}

.special-column p {
  line-height: 1.5;
  margin: 0;
  margin-left: rem-calc(18);
  margin-right: rem-calc(18);
  margin-bottom: rem-calc(0);
}

.special-column .highlight-title {
  color: $pink;
}

.announcement-icon {
  display: block;
  margin: 0 auto;
  margin-bottom: rem-calc(36);
}

.inline-link {
  color: $dark-grey;
  text-decoration: underline;

  &:hover {
    color: $dark-blue;
  }
}

.special h3 {
  margin-bottom: rem-calc(38);
  margin-top: rem-calc(18);
  margin-left: rem-calc(18);
}

a:active, a:visited, a:focus {
  text-decoration: none;
}

h1 {
  letter-spacing: rem-calc(.75);
  color: $black;
  text-align: center;
  margin-bottom: rem-calc(36);
  font-weight: 600;
}

h2 {
  letter-spacing: rem-calc(.75);
  font-weight: 600;
}

h3 {
  letter-spacing: rem-calc(.75);
  font-size: rem-calc(20);
  font-weight: 700;
}

h4 {
  letter-spacing: rem-calc(.75);
  color: $dark-blue;
  font-size: rem-calc(18);
}

p {
  font-size: rem-calc(16);
  color: $dark-grey;
  font-family: 'Georgia', sans-serif;

  @include breakpoint(medium) {
    font-size: rem-calc(18);
  }
}

.no-subheader {
  margin-bottom: rem-calc(50);
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
  margin-bottom: rem-calc(6);
}

form .error {
  font-size: rem-calc(14);
  font-weight: 500;
  color: #cc4b37;
  font-style: italic;
  font-family: 'Georgia', sans-serif;
}

.contact-number {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: rem-calc(22);
  font-weight: 700;
  color: $black;
}

.sub-header {
  font-size: rem-calc(18);
  color: $dark-grey;
  text-align: center;
  margin-bottom: rem-calc(50);
  font-family: 'Georgia', sans-serif;

  @include breakpoint(medium) {
    margin-bottom: rem-calc(75);
    font-size: rem-calc(22);
  }

  &.textleft, &.text-left {
    text-align: left;
  }

  &.nobottom-space {
    margin-bottom: 0;
  }
}

.linguist-schools {
  text-align: center;

  img {
    margin-bottom: rem-calc(36);
  }

  .button {
    margin-top: rem-calc(24);
  }

  @include breakpoint(medium up) {
    text-align: left;

    img {
      margin-bottom: auto;
    }
  }
}

.linguist-certifications {

  h1 {
    margin-bottom: rem-calc(36);
  }
}

.service-area {
  margin-top: rem-calc(70);

  &:nth-child(3), &:nth-child(4) {
    margin-top: rem-calc(25);
  }
}

h1 {
  margin-bottom: rem-calc(18);
}

.service-area h3 {
  position: relative;

  img {
    position: absolute;
    left: rem-calc(-36);
    width: rem-calc(36);
    height: rem-calc(36);
  }

  ul {
    margin-left: 0;
  }

  li {
    list-style-type: none;
    color: #6B7C93;
    font-size: rem-calc(18);
    line-height: rem-calc(38);
  }
}

.inline-link {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.banner {
  padding: rem-calc(110 0 30 0);
  background: #3872bd;

  @include breakpoint(medium) {
    padding: rem-calc(140 0 30 0);
  }

  @include breakpoint(large) {
    padding: rem-calc(100 0 30 0);
  }

  h1, p {
    color: $white;
  }

  h1 {
    font-size: rem-calc(26);

    @include breakpoint(large up) {
      font-size: rem-calc(32);
    }
  }

  p {
    margin-bottom: 0;
  }
}

.intro-image {
  margin-bottom: rem-calc(50);
  border-radius: rem-calc(4);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @include breakpoint(medium up) {
    margin-bottom: rem-calc(75);
  }
}

.team-photos {
  .photo {
    max-width: rem-calc(150);
    margin: 0;
    padding: 0;
  }

  h1 {
    margin-bottom: rem-calc(75);
  }

  h3 {
    margin-top: rem-calc(12);
    margin-bottom: 0;
    font-size: rem-calc(16);

    @include breakpoint(medium up) {
      font-size: rem-calc(18);
    }
  }

  p {
    text-align: center;
    font-size: rem-calc(16);
    padding-bottom: rem-calc(12);
    font-size: rem-calc(14);

    @include breakpoint(medium up) {
      font-size: rem-calc(16);
    }
  }

  img {
    border-radius: rem-calc(4);
    box-shadow: 0px 0px 30px #ECECEC;
    max-width: rem-calc(130);
    height: auto;

    @include breakpoint(medium up) {
      max-width: rem-calc(175);
    }
  }
}

.languages-callout {
  text-align: center;

  ul {
    margin: 0;
  }

  li {
    list-style-type: none;
    font-size: rem-calc(18);
    color: $dark-grey;
    line-height: 2;
  }

  img {
    margin-bottom: rem-calc(18);
    width: rem-calc(30);
    height: rem-calc(30);
  }

  .button {
    margin-top: rem-calc(30);
  }
}

.located {
  p {
    text-align: left;

    @include breakpoint(medium up) {
      margin-right: rem-calc(56);
      text-align: left;
    }
  }

  h1 {
    text-align: center;
    margin-top: rem-calc(0);

    @include breakpoint(medium) {
      text-align: left;
      margin-right: rem-calc(0);
    }

    @include breakpoint(large up) {
      margin-top: rem-calc(85);
      margin-right: rem-calc(56);
      text-align: left;
    }
  }

  .card.vertical {
    margin-bottom: 0;

    h3 {
      padding-top: rem-calc(24);
    }

    img {
      height: rem-calc(250);
      width: 100%;
      overflow-x: hidden;
    }
  }
}

.icon-left-large {
  img {
    width: rem-calc(50);
    height: rem-calc(50);
    margin-right: rem-calc(16);
  }

  ul {
    margin-left: rem-calc(64);
  }

  li {
    list-style-type: none;
    font-size: rem-calc(18);
    color: $dark-grey;
    line-height: 2;
  }

  p {
    margin-left: rem-calc(66);
    margin-top: rem-calc(-12);
  }

  a h4 {
    color: $dark-blue;
    margin-bottom: rem-calc(18);
    font-size: rem-calc(18);
    margin-left: rem-calc(66);
  }

  @include breakpoint(medium up) {
    margin-left: rem-calc(0);
  }
}

.icon-left-small {
  img {
    width: rem-calc(25);
    height: rem-calc(25);
    margin-right: rem-calc(12);
  }

  h3 {
    margin-top: rem-calc(36);
  }

  @include breakpoint(medium up) {
    h3 {
      margin-top: rem-calc(0);
    }

    p {
      margin-left: rem-calc(36);
    }
  }
}

hr {
  border-bottom: 1px solid #E1E1E1;
  margin: rem-calc(60 0 100 0);
}

.call-to-action-callout {
  margin-bottom: rem-calc(36);

  hr {
    margin: rem-calc(36 0 80 0);
    border-bottom: 1px solid #AFC5DC;
  }

  p {
    margin-bottom: rem-calc(36);
  }

  .button {
    width: rem-calc(256);
    margin: rem-calc(6 0);
  }

  @include breakpoint(medium up) {
    .button {
      margin-top: rem-calc(24);
      width: auto;
      margin: rem-calc(0 6);
    }
  }
}

a[href^=tel] {
  color: inherit !important;
  text-decoration: none !important;
  display: inline !important;
}

.resources {
  h2 {
    font-weight: 700;
    font-size: rem-calc(22);
    display: inline-block;
    margin-left: rem-calc(8);
    margin-bottom: rem-calc(45);

    @include breakpoint(medium up) {
      font-size: rem-calc(24);
    }
  }

  li {
    text-align: left;
    margin-bottom: rem-calc(42);
    list-style-type: none;

    &:last-child {
      margin-bottom: rem-calc(0);
    }
  }

  h3 a {
    text-align: left;
    color: $black;

    &:hover {
      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      transition: .2s ease-in-out;
      color: $dark-blue;
    }
  }
}

.steps {
  position: relative;

  h3 {
    margin-top: rem-calc(24);
  }

  .steps-image {
    position: relative;
  }

  .steps-path {
    position: absolute;
  }
}

.checkmark {
  text-align: left;
  position: relative;
  list-style-type: none;
  font-size: rem-calc(18);
  line-height: 2;
  color: $dark-grey;
  padding-left: rem-calc(24);
}

.pricing {
  text-align: center;

  img {
    border: 1px solid #efefef;
    margin-bottom: rem-calc(36);
  }

  .button {
    margin-top: rem-calc(12);
  }

  @include breakpoint(large up) {
    margin-bottom: rem-calc(100);
    text-align: left;

    h1 {text-align: left;}

    img {margin-bottom: auto;}
  }

  @include breakpoint(medium) {
    img {
      width: rem-calc(400);
      height: auto;
    }
  }
}

.checkmark:before {
  position: absolute;
  content: '';
  background-image: url('../img/checkmark-pink-hollow.svg');
  background-size: rem-calc(12);
  width: rem-calc(12);
  height: rem-calc(12);
  left: rem-calc(0);
  margin-top: rem-calc(12);
}

.tabs-container {
  text-align: center;
}

.tabs {
  box-shadow: 0px 2px 10px #C1C1C1;
  z-index: 100;
  text-align: center;
  margin: auto 0;
  display: inline-block;
  margin-bottom: rem-calc(-7);
  border: none;

  .tabs-title {

  }

  .tabs-content {
    border: transparent;
  }
}

.quote-checkbox label {
  font-size: rem-calc(16);
  margin-bottom: rem-calc(36);
  font-family: 'Georgia', sans-serif;
}

.request-quote-form {
  text-align: left;
  padding: rem-calc(40 60);
  margin-top: 0;

  .required {
    position: relative;
    color: $pink;
    margin-left: rem-calc(0);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: rem-calc(16);
    font-weight: 700;
  }

  .has-tip {
    position: absolute;
    left: 0;
    bottom: rem-calc(6);
    content: '';
    background-image: url('../img/tooltip-icon.svg');
    background-size: rem-calc(16);
    background-position: center center;
    width: rem-calc(16);
    height: rem-calc(16);
    border-bottom: 0;
  }

  h3 {
    color: $black;
    margin-bottom: rem-calc(36);
  }

  label {
    font-size: rem-calc(16);
    color: $black;
    font-weight: 700;
    letter-spacing: .5px;
    margin-bottom: rem-calc(40);
    font-family: 'Source Sans Pro', sans-serif;

    input:focus, select:focus, textarea:focus {
      border: 1px solid $dark-blue;
    }

    select {
      font-family: 'Georgia', sans-serif;
      background-color: #F7F7FA;
      margin-bottom: 0.375rem;
    }

    input, textarea {
      font-family: 'Georgia', sans-serif;
    }
  }

  .button {
    margin-top: 0;
  }

  span {
    font-size: rem-calc(14);
    font-style: italic;
    display: inline-block;
    margin-left: rem-calc(12);
  }
}

.request-quote-form:first-of-type {
  margin-bottom: rem-calc(50);
}

.quote-confirmation {
  .button {
    @include breakpoint(small only) {
      width: rem-calc(250);
    }
  }

  .button + .button {
    margin-right: rem-calc(10);
    margin-left: rem-calc(10);

    @include breakpoint(small only) {
      margin: auto;
    }
  }
}

.terms {
  font-size: rem-calc(18);
  font-weight: 600;
  color: $dark-grey;
  cursor: pointer;

  &:hover {
    color: $dark-blue;
  }

  @include breakpoint(medium up) {
    font-size: rem-calc(14);
  }
}

.policy {
  h1 {
    font-size: rem-calc(24);
    font-weight: 600;
  }

  h2 {
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
  }

  h3 {
    color: $dark-grey;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
  }

  h4 {
    color: $dark-grey;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
  }

  li {
    font-size: rem-calc(16);
    color: $dark-grey;
    font-family: 'Georgia', sans-serif;
    margin-bottom: rem-calc(8);

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
}

//Temp fix for anchor problem created by fixed header
#contact-excel-information, #excel-service, #join-excel {
  &:before {
    content: "";
    display: block;
    height: 50px;
    margin: -30px 0 0;
  }
}
