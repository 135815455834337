.languages {
  background: url('../img/language-background.jpg') no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
}

.herounit {
  background: url('../img/hero-excel-small.png') no-repeat;
  background-size: cover;
  background-position: center;
  height: rem-calc(625);
  width: 100%;

  @include breakpoint(medium) {
    background: url('../img/hero-excel.png') no-repeat;
    background-size: cover;
    background-position: center;
    height: rem-calc(500);
    width: 100%;
    padding-left: rem-calc(50);

    .button {
      margin-left: rem-calc(0);
    }
  }

  @include breakpoint(large) {
    background: url('../img/hero-excel.png') no-repeat;
    background-size: cover;
    background-position: center;
    height: rem-calc(530);
    width: 100%;
    padding-left: rem-calc(0);

    // .button:first-child {
    //   margin-right: rem-calc(12);
    // }
  }

  // @include breakpoint(xlarge up) {
  //   background: url('../img/heronew.png') no-repeat;
  //   background-size: cover;
  //   background-position: center right;
  //   height: rem-calc(400);
  // }

  h1 {
    padding-top: rem-calc(105);
    font-size: rem-calc(23);
    text-align: center;
    color: white;

    @include breakpoint(medium) {
      padding-top: rem-calc(175);
      font-size: rem-calc(26);
      text-align: left;
    }

    @include breakpoint(large up) {
      padding-top: rem-calc(132);
      padding-right: rem-calc(50);
      font-size: rem-calc(32);
      text-align: left;
    }
  }

  .button:first-child {
    @include breakpoint(medium up) {
      margin-right: rem-calc(12);
    }
  }

  .hero-buttons {
    text-align: center;

    @include breakpoint(medium up) {
      text-align: left;
    }

    .secondary-button {
      background: rgba(255,255,255,0.0);
      border: 1px solid $white;

      &:hover {
      background: rgba(255,255,255,0.8);
      color: $dark-blue;
      }
    }

    a {
      font-size: rem-calc(13);

      @include breakpoint(large up) {
        font-size: rem-calc(16);
      }

      @include breakpoint(medium only) {
        font-size: rem-calc(13);
      }
    }
  }

  p {
    text-align: center;
    margin: rem-calc(18 0 45 0);
    font-size: rem-calc(16);
    color: #fff;

    @include breakpoint(medium) {
      font-size: rem-calc(22);
      text-align: left;
      display: block;
    }

    @include breakpoint(large up) {
      text-align: left;
    }
  }

  .herounit-text {
    display: block;
    margin: auto 0;
  }
}

.herounit.translating {
  background: url('../img/excel-translating-sacramento-heropage.png') no-repeat;
  background-position: center top;
  background-size: cover;
  min-height: rem-calc(475);
  width: 100%;

  p {
    display: inline-block;
    font-size: rem-calc(24);
    text-align: center;
    color: $white;
    padding-top: 0;
    margin-top: 0;
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 1);
  }

  @include breakpoint(medium) {
    background: url('../img/excel-translating-sacramento-heropage.png') no-repeat;
    background-size: cover;
    background-position: center bottom;
    min-height: rem-calc(550);
    width: 100%;
    p {font-size: rem-calc(28);}
  }

  @include breakpoint(large up) {
    background: url('../img/excel-translating-sacramento-heropage.png') no-repeat;
    background-size: cover;
    background-position: center bottom;
    min-height: rem-calc(575);
    width: 100%;
  }

  h1 {
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 1);
    padding: rem-calc(200) 0 0 0;
    font-size: rem-calc(26);
    text-align: center;
    color: $white;

    @include breakpoint(medium) {
      padding-top: rem-calc(200);
      font-size: rem-calc(32);
    }

    @include breakpoint(large up) {
      padding-top: rem-calc(186);
      font-size: rem-calc(36);
    }
  }

  .hero-buttons {
    text-align: center;
  }

  .herounit-text {
    display: block;
    margin: auto 0;
    text-align: center;
  }
}
